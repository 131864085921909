<script>
import { isBrazil } from '@utils/helper-region';

export default {
  name: 'KoviAddressAutocomplete',
  props: {
    id: {
      type: String,
      default: 'kovi-address-autocomplete'
    },
    className: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: 'BR'
    },
    placeholder: {
      type: String,
      default: 'Digite seu endereço'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      address: {},
      autocomplete: {},
    }
  },
  mounted() {
    const input = this.$refs['google-autocomplete'];

    const country = isBrazil() ? 'br' : 'mx'

    const options = {
      types: ['address'],
      strictBounds: true
    }

    this.autocomplete = new google.maps.places.Autocomplete(input, options);

    this.autocomplete.setComponentRestrictions({country});
    this.autocomplete.setFields(['address_component', 'geometry', 'types']);
    this.autocomplete.addListener( 'place_changed', this.placeChanged);
  },
  methods: {
    placeChanged() {
      const address = this.autocomplete.getPlace();
      this.$emit('input', address);
    }
  },
  render(h) {
    return h(
      'input',
      {
        attrs: {
          autocomplete: 'off',
          placeholder: this.placeholder,
          type: 'text',
        },
        class: this.className,
        id: this.id,
        ref: 'google-autocomplete',
      }
    );
  }
}
</script>

<style lang="scss">
  .pac-container {
    z-index: 999999999;
  }
</style>
